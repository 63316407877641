@import './src/global.scss';
:root {
	--grid-gutter: 32px;
	--body-color: #3b3014;
	--color-chocolate: #3a3017;
	--color-orange: #e99d0d;
	--color-lite-orange: #fdc124;
	--color-white: #ffffff;
	--color-burnt: #bf5329;
	--color-body: var(--color-chocolate);
	--color-body-link: var(--color-burnt);
	--color-video-accent: var(--color-orange);
	--font-primary: 'Roboto Slab', serif;
	--font-secondary: 'Anton', sans-serif;
	--spinner-color: var(--color-orange);
	--button-bg: var(--color-burnt);
	--button-bg-alt: #a43d15;
}

::selection {
	background: var(--color-orange);
	color: #000;
}

* {
	box-sizing: border-box;
}

html {
	font-size: 16px;
}

body {
	margin: 0;
	font-display: swap;
	background: url('./bg_tile.png') #f9f4ed center center repeat;
	background-size: 233px 174px;
}

body,
input,
button {
	font-family: var(--font-primary);
	color: var(--color-body);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

a {
	text-decoration: none;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}
